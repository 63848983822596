export default function highlight({ init, } = {}) {
    let hljs;
    return {
        viewerEffect({ markdownBody }) {
            ;
            (async () => {
                const els = markdownBody.querySelectorAll('pre>code');
                if (els.length === 0)
                    return;
                if (!hljs) {
                    hljs = await import('highlight.js');
                    if (init)
                        await init(hljs);
                }
                els.forEach((el) => {
                    hljs.highlightElement(el);
                });
            })();
        },
    };
}
