import remarkFrontmatter from 'remark-frontmatter';
import { safeLoad } from 'js-yaml';
export default function frontmatter({ onError, } = {}) {
    return {
        remark: (p) => p.use(remarkFrontmatter).use(() => (tree, file) => {
            // console.log(tree);
            const fisrtNode = tree.children[0];
            if ((fisrtNode === null || fisrtNode === void 0 ? void 0 : fisrtNode.type) !== 'yaml')
                return;
            try {
                file.frontmatter = safeLoad(fisrtNode.value);
            }
            catch (err) {
                onError === null || onError === void 0 ? void 0 : onError(err);
            }
        }),
    };
}
