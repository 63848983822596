import React, {useEffect, useState} from "react";
import $axios from "../config/axios/index";
import utils from "../utils";
import Header from "./components/header";
import NoData from "./components/NoData";
import MyLoading from "./components/Loading";
import Waterfall from "./components/Waterfall";
import InfiniteScroll from 'react-infinite-scroller'
import { useHistory } from "react-router-dom";
import MyMasonry from "./components/Masonry";
import {debounce} from "lodash"

const Home = () => {
    let history = useHistory();
    const [initData, setInitData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [markTag, setMarkTag] = useState(null)

    useEffect(() => {
        console.log('执行 useEffect')
        getList();
    }, []);

    useEffect(() => {
        if (markTag) {
            getList(1, markTag)
        }
    }, [markTag]);

    const handleMarkTag = val => {
        setMarkTag(val)
    }

    const handleSearch = (str) => {
        console.log(str)
        str = str.replace("markTag=-1","");
        const isDef = str.indexOf('markTag=-1') > -1;
        if (str || isDef || !str) setInitData([]);
        if (isDef) {
            getList(1)
        } else {
            handleMarkTag(str)
            // getList(1, str)
        }
    };

    const getList = debounce((current = 1, str = '') => {
        setLoading(true)
        return new Promise((resolve => {
            $axios.post(`https://admin.imcoding.top/api/mark/list?pageSize=9&current=${current}${str}`).then(res => {
                // $axios.post(`http://localhost:9998/mark/list?pageSize=20&current=${current}${str}`).then(res => {
                const data = utils.disposeWaterfall(res.data.data)
                let def = []
                if (!str) {
                    def = [...initData]
                }
                setInitData([...def, ...data]);
                setLoading(false);
                setHasMore(!!res.data.data.length);
                resolve(data)
            })
        }))
    }, 1000);

    const rateJsx = (markRate) => {
        return (
            <>
                <svg t="1644055387106" className="icon" viewBox="0 0 1024 1024" version="1.1"
                     xmlns="http://www.w3.org/2000/svg" p-id="3281" width="200" height="200">
                    <path d="M0 0m512 0l0 0q512 0 512 512l0 0q0 512-512 512l0 0q-512 0-512-512l0 0q0-512 512-512Z"
                          fill="#FFFFFF" p-id="3282"></path>
                    <path
                        d="M512 640l-104.5632 54.976a25.6 25.6 0 0 1-37.1456-26.9952l19.968-116.4288-84.5824-82.4576a25.6 25.6 0 0 1 14.1824-43.6608l116.9024-16.9856 52.288-105.9328a25.6 25.6 0 0 1 45.9008 0l52.288 105.9328 116.9024 16.9856a25.6 25.6 0 0 1 14.1824 43.6608l-84.5824 82.4576 19.968 116.4288a25.6 25.6 0 0 1-37.1456 26.9952L512 640z"
                        fill="#FCCF0A" p-id="3283"></path>
                </svg>
                <span>{markRate}</span>
            </>
        )
    }

    const options = {
        initialData: initData,
        onClick: (e, item) => {
            console.log(item)
            const {id} = item.data;
            history.push(`/detail/${id}`);
        },
        loadMore: async (current) => {
            return await getList(current)
        },
        render: (dataSource) => {
            const {data} = dataSource;
            return (
                <div className="waterfall-render">
                    <img src={dataSource.src} />
                    {data?.desc ? <div className="desc" title={data?.desc}>
                        {data?.desc}
                    </div> : ''}
                    <div className="bot">
                        <span className="city">{data?.name}</span>
                        <span className="rate">
                            {rateJsx(data?.markRate)}
                        </span>
                    </div>
                </div>
            )
        }
    }


    return (
        <>
            <div className="home"
                 style={{backgroundColor: "rgb(242, 246, 248)"}}>
                <Header onSearch={handleSearch}/>
                <InfiniteScroll
                    initialLoad={false} // 不让它进入直接加载
                    pageStart={1} // 设置初始化请求的页数
                    loadMore={getList}
                    hasMore={hasMore}
                    loader={<div className="loader" key={0}>Loading ...</div>}
                    useWindow>
                    <MyMasonry initialData={initData}/>
                </InfiniteScroll>
                {/*{*/}
                {/*    initData.length > 0 ? <MyWaterfall loadMore={getList} initialData={initData} />*/}
                {/*    : (!loading ? <NoData/> : '')*/}
                {/*}*/}
                {/*{*/}
                {/*    initData.length > 0 ? <Waterfall {...options} />*/}
                {/*        : (!loading ? <NoData/> : '')*/}
                {/*}*/}
                {/*{*/}
                {/*    loading && <MyLoading/>*/}
                {/*}*/}
            </div>
        </>
    )
}

export default Home;
