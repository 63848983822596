import React, {useEffect, useState} from "react";
import './waterfall.less';
import {useSelector} from "react-redux";

const Waterfall = (props) => {
    const {loadMore, initialData, render, onClick} = props;
    const [current, setCurrent] = useState(1);
    const [loading, setLoading] = useState(false);
    const [waterData, setWaterData] = useState([]);


    useEffect(() => {
        if (initialData.length && !waterData.length) {
            console.log('加载成功', [...waterData, ...initialData])
            setWaterData(initialData);
        }
    }, [initialData]);

    const waterDatas = useSelector((state) => {
        console.log('state', state)
        return waterData
    })


    const getScrollTop = () => {
        let scrollTop = 0, bodyScrollTop = 0, documentScrollTop = 0;
        if(document.body){
            bodyScrollTop = document.body.scrollTop;
        }
        if(document.documentElement){
            documentScrollTop = document.documentElement.scrollTop;
        }
        scrollTop = (bodyScrollTop - documentScrollTop > 0) ? bodyScrollTop : documentScrollTop;
        return scrollTop;
    };

    const consoleLog = () => {
        console.log(waterData)
    }

    const getScrollHeight = () => {
        let scrollHeight = 0, bodyScrollHeight = 0, documentScrollHeight = 0;
        if(document.body){
            bodyScrollHeight = document.body.scrollHeight;
        }
        if(document.documentElement){
            documentScrollHeight = document.documentElement.scrollHeight;
        }
        scrollHeight = (bodyScrollHeight - documentScrollHeight > 0) ? bodyScrollHeight : documentScrollHeight;
        return scrollHeight;
    };

    const getWindowHeight = () => {
        let windowHeight = 0;
        if(document.compatMode == 'CSS1Compat'){
            windowHeight = document.documentElement.clientHeight;
        }else{
            windowHeight = document.body.clientHeight;
        }
        return windowHeight;
    };

    const loadMores = async () => {
        const aa = waterData
        console.log('aaa', aa.length)
        setLoading(true);
        const res = await loadMore(current + 1);
        setCurrent(current + 1)
        console.log('res', [...waterData, 1, 2]);
        setWaterData([...waterData, ...res]);
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    };

    useEffect(() => {
        window.onscroll = function(){
            console.log(waterData)
            if(getScrollTop() + getWindowHeight() == getScrollHeight()){
                if (!loading) loadMores();
            }
        };
    });

    return (
        <div className="waterfall-container">
            <div className="waterfall">
                {
                    waterData.length && waterData.map((item, index) => (
                        <div className="item" onClick={e => onClick(e, item)} key={index}>
                            {render(item)}
                        </div>
                    ))
                }
            </div>
            <div style={{height: "36px"}}>
                {loading && <div
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                    <svg
                        width="50"
                        height="20"
                        viewBox="0 0 120 30"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#000"
                    >
                        <circle cx="15" cy="15" r="15">
                            <animate
                                attributeName="r"
                                from="15"
                                to="15"
                                begin="0s"
                                dur="0.8s"
                                values="15;9;15"
                                calcMode="linear"
                                repeatCount="indefinite"
                            />
                            <animate
                                attributeName="fill-opacity"
                                from="1"
                                to="1"
                                begin="0s"
                                dur="0.8s"
                                values="1;.5;1"
                                calcMode="linear"
                                repeatCount="indefinite"
                            />
                        </circle>
                        <circle cx="60" cy="15" r="9" fillOpacity="0.3">
                            <animate
                                attributeName="r"
                                from="9"
                                to="9"
                                begin="0s"
                                dur="0.8s"
                                values="9;15;9"
                                calcMode="linear"
                                repeatCount="indefinite"
                            />
                            <animate
                                attributeName="fill-opacity"
                                from="0.5"
                                to="0.5"
                                begin="0s"
                                dur="0.8s"
                                values=".5;1;.5"
                                calcMode="linear"
                                repeatCount="indefinite"
                            />
                        </circle>
                        <circle cx="105" cy="15" r="15">
                            <animate
                                attributeName="r"
                                from="15"
                                to="15"
                                begin="0s"
                                dur="0.8s"
                                values="15;9;15"
                                calcMode="linear"
                                repeatCount="indefinite"
                            />
                            <animate
                                attributeName="fill-opacity"
                                from="1"
                                to="1"
                                begin="0s"
                                dur="0.8s"
                                values="1;.5;1"
                                calcMode="linear"
                                repeatCount="indefinite"
                            />
                        </circle>
                    </svg>
                </div>
                }
            </div>
        </div>
    )
};

export default Waterfall
