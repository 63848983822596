import React, {useEffect} from "react";
import Masonry from "react-masonry-css";
import Item from "../Item";
import './index.less'

const masonryOptions = {
    // transitionDuration: 0,
    default: 2
};

const MyMasonry = props => {
    const {loadMore, initialData, render, onClick} = props;

    const imagesLoadedOptions = { background: '.my-bg-image-el' };
    useEffect(() => {
        console.log(initialData)
    }, [initialData])

    return (
        <Masonry
            elementType={'ul'}
            options={masonryOptions}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
            imagesLoadedOptions={imagesLoadedOptions}>
            {
                initialData &&
                initialData.map(item => (
                    <Item dataSource={item}/>
                ))
            }
        </Masonry>
    )
};

export default MyMasonry
