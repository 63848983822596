import {BrowserRouter, Routes, Route} from 'react-router-dom';
import CacheRoute, { CacheSwitch } from 'react-router-cache-route'
import './App.css';
import React from "react";
import Home from "./pages";
import MarkDetail from "./pages/detail";
import {createrBowserHistory} from "history";


function App() {
  return (
      <BrowserRouter>
          <CacheRoute unmount exact path="/" component={Home} />
          <Route exact path="/detail/:id" component={MarkDetail} />
      </BrowserRouter>
  );
}

export default App;
