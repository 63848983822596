const initialState = {
    menuName: '首页',
    current: '123123数据'
}

export default (state = initialState, action) => {
    console.log('action', action)
    switch (action.type) {
        case 'SWITCH_MEUN':
            return {
                ...state, // 保存上一个状态值的写法
                menuName: action.menuName
            }
        case 'SWITCH_CURRENT':
            return {
                ...state, // 保存上一个状态值的写法
                current: action.current
            }
        default:
            return { ...state }

    }
}
