import React, { useMemo, useEffect, useRef } from 'react';
import * as bytemd from 'bytemd';
export const Viewer = ({ value, sanitize, plugins }) => {
    var _a;
    const elRef = useRef(null);
    const file = useMemo(() => {
        try {
            return bytemd.getProcessor({ sanitize, plugins }).processSync(value);
        }
        catch (err) {
            console.error(err);
        }
    }, [value, sanitize, plugins]);
    useEffect(() => {
        const markdownBody = elRef.current;
        if (!markdownBody || !file)
            return;
        const cbs = plugins === null || plugins === void 0 ? void 0 : plugins.map(({ viewerEffect }) => viewerEffect === null || viewerEffect === void 0 ? void 0 : viewerEffect({ markdownBody, file }));
        return () => {
            cbs === null || cbs === void 0 ? void 0 : cbs.forEach((cb) => cb && cb());
        };
    }, [file, plugins]);
    return (React.createElement("div", { onClick: (e) => {
            var _a, _b;
            const $ = e.target;
            if ($.tagName !== 'A')
                return;
            const href = $.getAttribute('href');
            if (!(href === null || href === void 0 ? void 0 : href.startsWith('#')))
                return;
            (_b = (_a = elRef.current) === null || _a === void 0 ? void 0 : _a.querySelector('#user-content-' + href.slice(1))) === null || _b === void 0 ? void 0 : _b.scrollIntoView();
        }, ref: elRef, className: "markdown-body", dangerouslySetInnerHTML: { __html: (_a = file === null || file === void 0 ? void 0 : file.toString()) !== null && _a !== void 0 ? _a : '' } }));
};
