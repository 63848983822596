const def = [
    '',
    'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fitem%2F201602%2F04%2F20160204212153_LuzNC.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1646572858&t=cc459fe3229785a232efefc4e67a84f5',
    'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fup.enterdesk.com%2Fedpic%2Fa2%2F19%2Fd3%2Fa219d373eef1bba1c4184001f449dc71.jpg&refer=http%3A%2F%2Fup.enterdesk.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1646572858&t=422c47f398b34e4c73cdd1ab2e7a21d7',
    'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg9.51tietu.net%2Fpic%2F2019-091203%2Fdojlwu43o23dojlwu43o23.jpg&refer=http%3A%2F%2Fimg9.51tietu.net&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1646572858&t=574b2752d64310394af15b304f9cc289',
    'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fup.enterdesk.com%2Fedpic%2F07%2F87%2F58%2F078758f1bc5cd8e819b0efe53ca37b81.jpg&refer=http%3A%2F%2Fup.enterdesk.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1646572858&t=8f859fb8c2dc5bc06ede32023c466b5a',
    'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fhbimg.b0.upaiyun.com%2Fd507c38ae58b144e21fa9fec73b537030caa975829b9a-bi9tnS_fw658&refer=http%3A%2F%2Fhbimg.b0.upaiyun.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1646572858&t=deaa1fdb8270c652c22a7935e224e20c',
    'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fhbimg.b0.upaiyun.com%2Fb4189fdd5388073eac37731a1d5b2710ef151ede41b99-ncRGit_fw658&refer=http%3A%2F%2Fhbimg.b0.upaiyun.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1646572858&t=e95a18c4bac3ca6cbc98fafd6f1fec06',
    'https://gimg2.baidu.com/image_search/src=http%3A%2F%2F5b0988e595225.cdn.sohucs.com%2Fq_70%2Cc_zoom%2Cw_640%2Fimages%2F20200421%2F102e15a53033485eb224ac5ce7058282.jpeg&refer=http%3A%2F%2F5b0988e595225.cdn.sohucs.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1646572858&t=641d37ef2909dccf6e95c389041fb489',
    'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fpic1.win4000.com%2Fmobile%2F2018-07-16%2F5b4c3d5dcb808.jpg%3Fdown&refer=http%3A%2F%2Fpic1.win4000.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1646572858&t=437e8bd923bc6cfefc6ba80e495d8298',
    'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fd.zdqx.com%2Fwmxs_161205%2F006.jpg&refer=http%3A%2F%2Fd.zdqx.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1646572858&t=85bd82f324ee076b3dec3251bcc11f40',
    'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fpic1.win4000.com%2Fmobile%2F2018-07-16%2F5b4c3d5dcb808.jpg%3Fdown&refer=http%3A%2F%2Fpic1.win4000.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1646572858&t=437e8bd923bc6cfefc6ba80e495d8298',
    'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fhbimg.b0.upaiyun.com%2Fd507c38ae58b144e21fa9fec73b537030caa975829b9a-bi9tnS_fw658&refer=http%3A%2F%2Fhbimg.b0.upaiyun.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1646572858&t=deaa1fdb8270c652c22a7935e224e20c',
];
/**
 * 处理成瀑布流需要的数据格式
 */
const disposeWaterfall = (arr) => {
    return arr.reduce((pre, cur) => {
        const {markCover, markTitle, markDesc, markRate, id, createTime} = cur;
        // const testImg = Math.floor(Math.random() * 10+ 1);
        pre.push({
            src: markCover,
            // src: def[testImg],
            data: {
                name: markTitle,
                desc: markDesc,
                markRate,
                createTime,
                id,
            }
        });
        return pre
    }, [])
};

/**
 * 获取url上的参数
 */
const getQueryString = (name) => {
    const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
    const search = window.location.search.split('?')[1] || '';
    const r = search.match(reg) || [];
    return r[2];
};


/**
 * 数组对象 根据两个key 转成对象
 */
const ArrayToMapKey = (data, key, key2) => {
    const res = data.reduce((pre, cur) => {
        const ks = cur[key];
        const val = cur[key2];
        pre[ks] = val;
        return pre;
    }, {});
    return res;
};

export default {
    disposeWaterfall,
    getQueryString,
    ArrayToMapKey
}
