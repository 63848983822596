import React, {useEffect, useState} from 'react'
import { Viewer } from '@bytemd/react'
import $axios from "../../config/axios";
import {Swiper, ImageViewer, Rate} from "antd-mobile";
import styles from './index.less'
import { LocationFill } from 'antd-mobile-icons'
import utils from "../../utils";
import 'highlight.js/styles/atom-one-dark.css'
import gfm from '@bytemd/plugin-gfm'
import highlight from '@bytemd/plugin-highlight'
import frontmatter from '@bytemd/plugin-frontmatter'
import breaks from '@bytemd/plugin-breaks'
import footnotes from '@bytemd/plugin-footnotes'
import mediumZoom from '@bytemd/plugin-medium-zoom'
import math from '@bytemd/plugin-math'
import mermaid from '@bytemd/plugin-mermaid'
import 'bytemd/dist/index.css'
import 'highlight.js/styles/vs.css'
// import "github-markdown-css/github-markdown.css"
import "juejin-markdown-themes/dist/juejin.min.css"

const plugins = [
    gfm(),
    highlight(),
    frontmatter(),
    breaks(),
    footnotes(),
    mediumZoom(),
    math(),
    mermaid()
]

const MarkDetail = (props) => {
    const [detail, setDetail] = useState(null);
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [viewUrl, setViewUrl] = useState(null);
    const [tagList, setTagList] = useState({});
    const [files, setFiles] = useState([]);

    const videoMatch = ['mov', 'mp4', 'avi', 'rmvb', 'FLV'];

    const handleView = (url) => {
        setViewUrl(url);
        setVisible(true)
    };

    const isVideo = (str) => {
        let flag = false
      for (const item of videoMatch) {
          if (str.indexOf(item) > -1) {
              flag = true
              break;
          }
      }
      return flag
    };

    useEffect(() => {
        if (detail) {
            const {markFiles} = detail;
            console.log('markFiles', markFiles)
            if (markFiles) {
                const arr = markFiles.split(',');
                console.log(arr);
                setFiles(arr)
            }
        }
    }, [detail]);

    const getDetail = () => {
        const url = window.location.href
        const index = url.lastIndexOf("\/")
        const id = url.substring(index + 1, url.length);
        $axios.post(`https://admin.imcoding.top/api/mark/findById?id=${id}`).then(res => {
            const {data} = res.data;
            const {markTag} = data;
            try {
                if (markTag) {
                    data.markTag = JSON.parse(markTag)
                } else {
                    data.markTag = []
                }
            } catch (e) {
                console.error('tags', e)
            }
            setDetail(data);
            setLoading(false);
        })
    };

    const getTagsList = () => {
        $axios.post(`https://admin.imcoding.top/api/mark/tagList`).then(res => {
            const v = utils.ArrayToMapKey(res.data, 'id', 'tagName');
            setTagList(v)
        })
    };

    useEffect(() => {
        getTagsList()
        getDetail()
    }, []);
    return (
        <div>
            {
                detail &&
                <div className="detail">
                    <div className="img-box">
                        <Swiper>
                            {
                                files.map((item, index) => (
                                    <Swiper.Item key={index}>
                                        <div
                                            className={styles.content}
                                        >
                                            {
                                                isVideo(item) ? <video src={item} controls></video>
                                                    :
                                                    <img src={item} alt="" onClick={() => handleView(item)}/>
                                            }
                                        </div>
                                    </Swiper.Item>
                                ))
                            }
                        </Swiper>
                        <ImageViewer
                            image={viewUrl}
                            visible={visible}
                            onClose={() => {
                                setVisible(false)
                            }}
                        />
                    </div>
                    <div className={styles.detailInfo}>
                        <h3 className={styles.markTitle}>
                            {detail.markTitle}
                        </h3>
                        <div className={styles.markTable}>
                            <div className={styles.listDiv}>
                                <span className={styles.spanLabel}>
                                    评分：
                                </span>
                                <div className={styles.tableDiv}>
                                    <Rate readOnly value={detail.markRate || 0}/>
                                </div>
                            </div>
                            <div className={styles.listDiv}>
                                <span className={styles.spanLabel}>
                                    分类：
                                </span>
                                <div className={styles.tableDiv}>
                                    {
                                        detail.markTag.map((tag, tagIndex) => (
                                            <span className={styles.spanTags} key={tagIndex}>{tagList[tag]}</span>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className={styles.listDiv}>
                                <span className={styles.spanLabel}>
                                    更新于：
                                </span>
                                <div className={styles.tableDiv}>
                                    <span>{detail.createTime}</span>
                                </div>
                            </div>
                            {
                                detail.markDesc &&
                                <div className={styles.listDiv}>
                                    <span className={styles.spanLabel}>
                                        描述：
                                    </span>
                                    <div className={styles.tableDiv}>
                                        {detail.markDesc}
                                    </div>
                                </div>
                            }
                            <div className={styles.listDiv}>
                                <span className={styles.spanLabel}>
                                    城市：
                                </span>
                                <div className={styles.tableDiv}>
                                    {detail.markCity}
                                </div>
                            </div>
                            <div className={styles.listDiv}>
                                <span className={styles.spanLabel}>
                                    详细地址：
                                </span>
                                <div className={styles.tableDiv}>
                                    {detail.markAddress}
                                    <LocationFill style={{color: '#bfbfbf'}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bytemd-preview">
                        {
                            detail.markContent && <Viewer value={detail.markContent} plugins={plugins} />
                        }
                    </div>
                </div>
            }
        </div>
    )
};

export default MarkDetail;
