import React, {useState, useEffect} from 'react'
import {SearchBar} from "antd-mobile";
import styles from './index.less'
import $axios from "@/config/axios/index";

const Header = (props) => {
    const [tags, setTags] = useState([]);
    const [params, setParams] = useState({
        markTitle: null,
        markTag: null
    });

    const handleCheckTag = (item, index) => {
      const {id} = item;
      const def = [...tags];
      const _before = tags.findIndex(item => item.active);
      def[_before].active = false;
      def[index].active = true;
      setTags([...def]);
      setParams({
          ...params,
          markTag: id
      })
    };

    useEffect(() => {
        handleSearch()
    }, [params]);

    const handleSearch = () => {
        if (props.onSearch) {
            let str = '';
            Object.keys(params).forEach(key => {
                if (params[key]) {
                    str += `&${key}=${params[key]}`
                }
            });
            props.onSearch(str);
        }
    };

    const handleOnSearch = (val) => {
        setParams({
            ...params,
            markTitle: val
        });
    };

    useEffect(() => {
        $axios.get('https://admin.imcoding.top/api/mark/tagList').then(res => {
            const def = {
                id: -1,
                tagName: '最新',
                active: true
            };
            const {data} = res;
            data.unshift(def);
            setTags(data)
        })
    }, []);

    return (
        <>
            <div className={styles.myHeader}>
                <div className={styles.search}>
                    <SearchBar placeholder='请输入关键字'
                               onSearch={handleOnSearch}
                               onClear={() => handleOnSearch(null)}/>
                </div>
                <div className={styles.myTabs}>
                    {
                        tags.map((item, index) => (
                            <span className={item.active ? 'active': ''}
                                  onClick={() => handleCheckTag(item, index)}
                                  key={index}>
                                {item.tagName}
                            </span>
                        ))
                    }
                </div>
            </div>
        </>
    )
};

export default Header;
