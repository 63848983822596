import React from "react";
import { useHistory } from "react-router-dom";
import moment from 'moment'
import './index.less'

const Item = props => {
    const history = useHistory();
    const {dataSource} = props;

    const formatTime = val => {
        if (!val) return
        const time = moment(val).format('YYYY-MM-DD')
        return time
    }

    const rateJsx = (markRate) => {
        return (
            <>
                {
                    new Array(markRate).fill('').map(() => (
                        <svg t="1644055387106" className="icon" viewBox="0 0 1024 1024" version="1.1"
                             xmlns="http://www.w3.org/2000/svg" p-id="3281" width="200" height="200">
                            <path d="M0 0m512 0l0 0q512 0 512 512l0 0q0 512-512 512l0 0q-512 0-512-512l0 0q0-512 512-512Z"
                                  fill="#FFFFFF" p-id="3282"></path>
                            <path
                                d="M512 640l-104.5632 54.976a25.6 25.6 0 0 1-37.1456-26.9952l19.968-116.4288-84.5824-82.4576a25.6 25.6 0 0 1 14.1824-43.6608l116.9024-16.9856 52.288-105.9328a25.6 25.6 0 0 1 45.9008 0l52.288 105.9328 116.9024 16.9856a25.6 25.6 0 0 1 14.1824 43.6608l-84.5824 82.4576 19.968 116.4288a25.6 25.6 0 0 1-37.1456 26.9952L512 640z"
                                fill="#FCCF0A" p-id="3283"></path>
                        </svg>
                    ))
                }
                {/*<span>{markRate}</span>*/}
            </>
        )
    };

    return (
        dataSource &&
        <div className="index-item" onClick={() => history.push(`/detail/${dataSource.data.id}`)}>
            <img src={dataSource.src} />
            {/*<img*/}
            {/*    className="logo-img"*/}
            {/*    src="https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_92x30dp.png"*/}
            {/*    alt="구글 로고"*/}
            {/*/>*/}
            {dataSource?.data?.desc ? <div className="desc" title={dataSource.data?.desc}>
                {dataSource.data?.desc}
            </div> : ''}
            <div className="bot">
                <span className="city">{dataSource.data?.name}</span>
                <span className="rate">
                            {rateJsx(dataSource.data?.markRate)}
                        </span>
            </div>
            <div className="time">{formatTime(dataSource?.data?.createTime)}</div>
        </div>
    )
};

export default Item
